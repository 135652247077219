import styled from 'styled-components'
import ICO_LINKEDIN from '../../assets/images/icon-linkedin.png'
import ICO_MEDIUM from '../../assets/images/icon-medium.png'
import {
  URL_LINKEDIN,
  URL_MEDIUM,
} from '../../common/utils/constants'
import media from '../../common/utils/media'

const SocialLinks = () => {
  return (
    <StyledSocialLinks>
      <a href={URL_LINKEDIN} target="_blank" rel="noreferrer">
        <img className="img-linkedin" src={ICO_LINKEDIN} alt="LinkedIn" />
      </a>
      <a href={URL_MEDIUM} target="_blank" rel="noreferrer">
        <img className="img-medium" src={ICO_MEDIUM} alt="Medium" />
      </a>
    </StyledSocialLinks>
  )
}

export default SocialLinks

const StyledSocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img.img-linkedin {
    width: 35px;
    height: 29.75px;
  }

  img.img-medium {
    width: 31.5px;
    height: 31.5px;
  }

  ${media.tablet`
    justify-content: center;
  `}
`
