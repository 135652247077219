import { GridItem, Section } from './types'

import ICO_AI from '../../assets/images/icon-ai.svg'
import ICO_BANKING from '../../assets/images/icon-banking.svg'
import ICO_FX_PREDICTION from '../../assets/images/icon-fx-prediction.svg'
import ICO_INTERNATIONAL from '../../assets/images/icon-international.svg'
import ICO_LOCATION from '../../assets/images/icon-location.svg'
import ICO_SCIENCE from '../../assets/images/icon-science.svg'
import ICO_SOFTWARE from '../../assets/images/icon-software.svg'

import IMG_AAA from '../../assets/images/img-aaa.png'
import IMG_AIS from '../../assets/images/img-ais.png'
import IMG_FLOW from '../../assets/images/img-flow.png'
import IMG_FX_PREDICTION from '../../assets/images/img-fx-prediction.png'

export const ROUTE_ROOT = '/'
export const ROUTE_ABOUT_US = '/about-us'
export const ROUTE_ABOUT_US_WITH_LANG = '/:lang/about-us'
export const ROUTE_HIRING = '/jobs'
export const ROUTE_CONTACT_US = '/contact-us'
export const ROUTE_CONTACT_US_WITH_LANG = '/:lang/contact-us'
export const ROUTE_NEWS_RELEASE = '/news-release'
export const ROUTE_NEWS_RELEASE_WITH_LANG = '/:lang/news-release'
export const ROUTE_NEWS_RELEASE_ARTICLE = '/news-release/:article'
export const ROUTE_NEWS_RELEASE_ARTICLE_WITH_LANG = '/:lang/news-release/:article'
export const ROUTE_JOB = '/jobs/:job'
export const ROUTE_JOB_WITH_LANG = '/:lang/jobs/:job'
export const ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG =
  '/:lang/anti-social-forces-basic-principle'
export const ROUTE_PRIVACY_POLICY_WITH_LANG = '/:lang/privacy-policy'
export const ROUTE_SITE_POLICY_WITH_LANG = '/:lang/site-policy'

export const HOMEPAGE_TITLE_FIRST: string = 'Financial Advantage'
export const HOMEPAGE_TITLE_LAST: string = 'through Technology'
export const HOMEPAGE_DESCRIPTION: string =
  "AlpacaTech is a technology company that develops investment management and trading solutions. \n\nWe combine cutting-edge technology and financial know-how to deliver sophisticated products that meet our clients' high standards."
export const COMPANY_ADDRESS: string =
  '1-13-14-2F Uchikanda, Chiyoda-ku, Tokyo, 101-0047'
export const COMPANY_PHONE: string = '+81 3 6837 2700'
export const COMPANY_EMAIL: string = 'info@alpaca-tech.ai'

export const URL_LINKEDIN: string =
  'https://www.linkedin.com/company/alpacatech/'
export const URL_MEDIUM: string = 'https://www.medium.com/alpaca-tech'
export const URL_APPLY_JOB: string = 'https://docs.google.com/forms/d/e/1FAIpQLSc8fDuNJbfFeX7RGZWBFsREI5-bfIDjAZcM5QUgSfBKMOMppg/viewform'

export const PRODUCT_GRID_ITEMS: GridItem[] = [
  {
    icon: '',
    title: 'AlpacaTech Investment Management',
    description:
      'AlpacaTech Investment Management (AIM) provides scalable asset management solutions, leveraging state-of-the-art research with a flexible publishing platform that timestamps asset allocations in the Blockchain via multiple Bitcoin transactions for a tamper proof track record.',
    contents: [
      {
        title: 'AlpacaTech Asset Allocation (AAA)',
        description:
          'AAA offers efficient asset allocation strategies driven by AI & quantitative research developed for customized investment goals.',
        image: IMG_AAA,
        className: 'product-aim-aaa',
      },
      {
        title: 'AlpacaTech Index Service (AIS)',
        description:
          '<a href="https://ais.asp.alpaca.ai" target="_blank">AIS</a> is a multi-purpose index publishing platform to launch and maintain investment strategies enabling a verifiable track record.',
        image: IMG_AIS,
        className: 'product-aim-ais',
      },
    ],
  },
  {
    icon: '',
    title: 'AlpacaTech Flow',
    description:
      'AlpacaTech Flow provides exposure management systems for market makers using AI-driven price prediction technology and optimizes risk exposure by learning the behaviour of order flow hour by hour.',
    contents: [
      {
        image: IMG_FLOW,
        className: 'product-flow',
      },
    ],
  },
]

export const RESEARCH_GRID_ITEMS: GridItem[] = [
  {
    icon: ICO_FX_PREDICTION,
    title: 'AlpacaTech FX short-term predictions',
    description:
      'AlpacaTech FX short-term prediction is an active research unit focusing on studying the predictive power of Deep Neural Networks applied to the FX market with time horizons shorter than a day.',
    contents: [
      {
        image: IMG_FX_PREDICTION,
      },
    ],
  },
]

export const WHY_US_GRID_ITEMS: GridItem[] = [
  {
    icon: ICO_AI,
    title: 'Artificial Intelligence',
    description:
      'A pool of talented data scientists from different sub-fields bring together the necessary know-how to assess which machine learning technology is optimal.',
  },
  {
    icon: ICO_BANKING,
    title: 'Banking Industry',
    description:
      'Veterans from large, established financial institutions have joined AlpacaTech to help us tackle big challenges in the financial services sector.',
  },
  {
    icon: ICO_SOFTWARE,
    title: 'Software & Data',
    description:
      'Our software and data engineering teams bring years of experience and deep-expertise to develop robust software systems that support AlpacaTech products.',
  },
  {
    icon: ICO_INTERNATIONAL,
    title: 'International',
    description:
      'AlpacaTech is an international and multi-cultural company. We operate globally and can assist you in many different languages.',
  },
  {
    icon: ICO_SCIENCE,
    title: 'Science',
    description:
      'Our scientists keep up with the latest research in machine learning, finance, and cryptocurrencies to help deliver state-of-the-art technology to our clients.',
  },
  {
    icon: ICO_LOCATION,
    title: 'Japan',
    description:
      'We’re headquartered in Japan and have a deep understanding of Japanese financial markets and services.',
  },
]

export const SECTIONS: Section[] = [
  {
    title: 'Products',
    description: '',
    gridItems: PRODUCT_GRID_ITEMS,
  },
  {
    title: 'Research',
    description:
      'As a technology company we are performing in-house research projects to ensure our clients benefit from the latest innovations.',
    gridItems: RESEARCH_GRID_ITEMS,
  },
  {
    title: 'Why Us',
    description:
      'AlpacaTech is a technology company that combines expertise from a wide variety of industries to ensure our clients truly experience the use of most modern technologies for their business.',
    gridItems: WHY_US_GRID_ITEMS,
  },
]
