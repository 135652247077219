import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  ROUTE_HIRING,
  ROUTE_JOB,
  ROUTE_JOB_WITH_LANG,
  ROUTE_ROOT,
  ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG,
  ROUTE_PRIVACY_POLICY_WITH_LANG,
  ROUTE_ABOUT_US,
  ROUTE_ABOUT_US_WITH_LANG,
  ROUTE_CONTACT_US,
  ROUTE_CONTACT_US_WITH_LANG,
  ROUTE_SITE_POLICY_WITH_LANG,
  ROUTE_NEWS_RELEASE,
  ROUTE_NEWS_RELEASE_WITH_LANG,
  ROUTE_NEWS_RELEASE_ARTICLE,
  ROUTE_NEWS_RELEASE_ARTICLE_WITH_LANG,
} from './common/utils/constants'
import HiringPage from './components/pages/HiringPage'
import HomePage from './components/pages/HomePage'
import JobPostingPage from './components/pages/JobPostingPage'
import DisclaimerPage from './components/pages/DisclaimerPage'
import AboutUsPage from './components/pages/AboutUsPage'
import ContactUsPage from './components/pages/ContactUsPage'
import NewsReleasePage from './components/pages/NewsReleasePage'
import NewsReleaseArticlePage from './components/pages/NewsReleaseArticlePage'

export const Router = () => {
  return (
    <BrowserRouter forceRefresh >
      <Switch>
        <Route path={ROUTE_JOB_WITH_LANG} component={JobPostingPage} exact />
        <Route path={ROUTE_JOB} component={JobPostingPage} exact />
        <Route path={ROUTE_HIRING} component={HiringPage} exact />

        <Route path={ROUTE_NEWS_RELEASE_WITH_LANG} component={NewsReleasePage} exact />
        <Route path={ROUTE_NEWS_RELEASE} component={NewsReleasePage} exact />
        <Route path={ROUTE_NEWS_RELEASE_ARTICLE_WITH_LANG} component={NewsReleaseArticlePage} exact />
        <Route path={ROUTE_NEWS_RELEASE_ARTICLE} component={NewsReleaseArticlePage} exact />

        <Route path={ROUTE_ABOUT_US_WITH_LANG} component={AboutUsPage} exact />
        <Route path={ROUTE_ABOUT_US} component={AboutUsPage} exact />

        <Route path={ROUTE_CONTACT_US_WITH_LANG} component={ContactUsPage} exact />
        <Route path={ROUTE_CONTACT_US} component={ContactUsPage} exact />

        <Route path={ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG} exact>
          <DisclaimerPage routePath={ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG} />
        </Route>
        <Route path={ROUTE_PRIVACY_POLICY_WITH_LANG} exact>
          <DisclaimerPage routePath={ROUTE_PRIVACY_POLICY_WITH_LANG} />
        </Route>
        <Route path={ROUTE_SITE_POLICY_WITH_LANG} exact>
          <DisclaimerPage routePath={ROUTE_SITE_POLICY_WITH_LANG} />
        </Route>
        <Route path={ROUTE_ROOT} component={HomePage} />
      </Switch>
    </BrowserRouter>
  )
}
